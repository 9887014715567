import React from "react";
import httpClient from "../../../api/httpClient";
import SearchBox from "./SearchBox";
import { SearchBoxController } from "./controllers";
import { IconSearch } from "@tabler/icons-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { SearchEngineConfiguration } from "@coveo/headless";

export interface GlobalSearchStandaloneProps {
  readonly accessToken: string;
  readonly organizationId: string;
  readonly organizationEndpoints: {
    readonly platform: string;
    readonly analytics: string;
    readonly search: string;
  };
  readonly variant: "popover" | "inline";
}

const closeMobileMenu = () => {
  const closeButton = document.querySelector<HTMLButtonElement>(
    ".navbar-v2-mobile-close-button button",
  );

  if (closeButton) {
    closeButton.click();
  }
};

export const GlobalSearchStandalone = ({
  accessToken,
  organizationId,
  organizationEndpoints,
  variant,
}: GlobalSearchStandaloneProps) => {
  const [open, setOpen] = React.useState(false);

  const onSearchSubmit = (value: string) => {
    closeMobileMenu();
    setOpen(false);
    window.location.href = `/global-search#q=${value}`;
  };

  const configuration: SearchEngineConfiguration = {
    search: { pipeline: "Global Search" },
    accessToken,
    organizationId,
    organizationEndpoints,
    renewAccessToken() {
      return httpClient({
        url: "/graphql",
        method: "post",
        data: JSON.stringify({
          query:
            "mutation($searchHub: String!) { coveoJwtCreate(input: { searchHub: $searchHub }) { jwt } }",
          variables: { searchHub: "GlobalSearch" },
        }),
      }).then((response) => response.data.data.coveoJwtCreate.jwt);
    },
  };

  const { searchBox } = SearchBoxController(configuration);

  return (
    <div>
      {variant === "inline" ? (
        <SearchBox controller={searchBox} onSearchSubmit={onSearchSubmit} />
      ) : (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <span className="flex items-end cursor-pointer">
              <IconSearch className="navbar-v2__utility-link" />
            </span>
          </PopoverTrigger>
          <PopoverContent
            className="tw-popover p-0 mt-5 outline-0 rounded-lg"
            side="bottom"
            align="end"
          >
            <SearchBox controller={searchBox} onSearchSubmit={onSearchSubmit} />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default GlobalSearchStandalone;
