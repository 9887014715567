import {
  buildSearchEngine,
  loadFieldActions,
  SearchEngine,
  SearchEngineConfiguration,
} from "@coveo/headless";

const FIELDS = ["ec_images"];

const registerAdditionalFields = (headlessEngine: SearchEngine) => {
  const fieldActions = loadFieldActions(headlessEngine);
  headlessEngine.dispatch(fieldActions.registerFieldsToInclude(FIELDS));
  return headlessEngine;
};

export const headlessEngine = (configuration: SearchEngineConfiguration) =>
  registerAdditionalFields(
    buildSearchEngine({
      configuration,
    }),
  );
